'use client';

import { usePathname } from 'next/navigation';

import { Separator } from '@/components';
import { cn, PATHNAME, triggerPathname } from '@/utils';

import {
  FooterContacts,
  FooterHeader,
  FooterLinks,
  FooterMedias,
  FooterRights,
  FooterVerifications,
} from './components';

const {
  LOCAL: {
    CART: { BASE_URL: CART_BASE_URL },
    CHECKOUT: { BASE_URL: CHECKOUT_BASE_URL },
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
  },
} = PATHNAME;

type PublicFooterProps = {
  excludedPathnames: string[];
};

const PublicFooter = ({ excludedPathnames }: PublicFooterProps) => {
  const pathname = usePathname();

  const triggeredExcludedPathname = triggerPathname(
    pathname,
    excludedPathnames,
  );
  const triggeredFooterSpacePathname = triggerPathname(pathname, [
    CART_BASE_URL,
    CHECKOUT_BASE_URL,
    CATEGORY_BASE_URL,
  ]);

  if (!triggeredExcludedPathname) {
    return (
      <footer
        className={cn(
          'bg-muted',
          triggeredFooterSpacePathname && 'max-lg:pb-16',
        )}
      >
        <div className='container grid items-center gap-x-4 gap-y-8 py-8 lg:grid-cols-12 lg:gap-y-12 lg:py-16'>
          <div className='self-start lg:col-span-3 lg:col-start-1 lg:row-start-3'>
            <FooterHeader />
          </div>
          <div className='lg:col-span-4 lg:col-start-9 lg:row-start-1 lg:justify-self-end'>
            <FooterMedias />
          </div>
          <Separator className='lg:col-span-full lg:row-start-2' />
          <div className='lg:col-span-8 lg:col-start-1 lg:row-start-1'>
            <FooterContacts />
          </div>
          <section className='lg:col-span-9 lg:col-start-4 lg:row-start-3'>
            <FooterLinks />
          </section>
          <Separator className='lg:col-span-full lg:row-start-4' />
          <div className='lg:col-span-4 lg:col-start-9 lg:row-start-5 lg:justify-self-end'>
            <FooterVerifications />
          </div>
          <section className='lg:col-span-8 lg:col-start-1 lg:row-start-5'>
            <FooterRights />
          </section>
        </div>
      </footer>
    );
  }
};

export { PublicFooter };
