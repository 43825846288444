import Image from 'next/image';

import Association from '@/assets/images/association-logo.png';
import Enamad from '@/assets/images/enamad-logo.png';
import Samandehi from '@/assets/images/samandehi-logo.png';
import { Button, ExternalLink } from '@/components';
import { PATHNAME, randomId } from '@/utils';

const {
  VERIFICATION: {
    ENAMAD: { BASE_URL: ENAMAD_BASE_URL, SLUG: ENAMAD_SLUG },
  },
} = PATHNAME;

const FooterVerifications = () => (
  <div className='flex items-start justify-center gap-4 lg:gap-8'>
    {[
      // {
      //   title: 'سازمان ثبت ملی',
      //   href: '#',
      //   icon: Samandehi,
      // },
      {
        title: 'نماد اعتماد الکترونیکی',
        href: `${ENAMAD_BASE_URL}${ENAMAD_SLUG}`,
        icon: Enamad,
      },
      // {
      //   title: 'انجمن صنفی کسب و کار',
      //   href: '#',
      //   icon: Association,
      // },
    ].map(({ title, href, icon }) => {
      const id = randomId();

      const { width, height } = {
        width: 76,
        height: 84,
      };

      return (
        <Button asChild key={id} variant='ghost' className='h-auto'>
          <ExternalLink href={href} referrerPolicy='origin' rel=''>
            <Image src={icon} width={width} height={height} alt={title} />
            <span className='sr-only'>{title}</span>
          </ExternalLink>
        </Button>
      );
    })}
  </div>
);

export { FooterVerifications };
