'use client';

import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import { Separator, Skeleton } from '@/components';
import { triggerPathname } from '@/utils';

import { HeaderAction } from './components';

const HeaderNavbar = dynamic(
  () => import('./components').then(mod => mod.HeaderNavbar),
  {
    ssr: false,
    loading: () => <Skeleton className='h-6 w-36 max-lg:size-6' />,
  },
);
const HeaderLogo = dynamic(
  () => import('@/components').then(mod => mod.KashilandLogo),
  {
    ssr: false,
    loading: () => <Skeleton className='h-11 w-40' />,
  },
);
const HeaderSearch = dynamic(
  () => import('./components').then(mod => mod.HeaderSearch),
  {
    ssr: false,
    loading: () => (
      <Skeleton className='h-[46px] w-full lg:mx-auto lg:max-w-screen-sm' />
    ),
  },
);

type PublicHeaderProps = {
  excludedPathnames: string[];
};

const PublicHeader = ({ excludedPathnames }: PublicHeaderProps) => {
  const pathname = usePathname();

  const triggeredExcludedPathname = triggerPathname(
    pathname,
    excludedPathnames,
  );

  if (!triggeredExcludedPathname) {
    return (
      <header className='pt-2.5 max-lg:space-y-4'>
        <div className='container grid grid-cols-12 grid-rows-2 items-center gap-x-1 gap-y-2 lg:gap-x-2'>
          <div className='col-span-1 max-lg:max-w-max lg:col-span-full lg:row-start-2'>
            <HeaderNavbar />
          </div>
          <div className='col-span-6 md:max-lg:-ms-[5vw] lg:col-span-2'>
            <HeaderLogo />
          </div>
          <div className='col-span-2 col-start-11'>
            <HeaderAction />
          </div>
          <div className='col-span-12 row-start-2 lg:col-span-6 lg:col-start-4 lg:row-start-1'>
            <HeaderSearch />
          </div>
        </div>
        <Separator />
      </header>
    );
  }
};

export { PublicHeader };
