'use client';

import { Fragment, type TouchEvent, useEffect, useMemo, useState } from 'react';

import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';

import { useQueries } from '@tanstack/react-query';
import { useBoolean, useMediaQuery } from 'usehooks-ts';

import {
  ArrowUpLeftIcon,
  BrandsIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  Button,
  CaretLeftIcon,
  CaretRightIcon,
  ListIcon,
  LocaleLink,
  NewspaperIcon,
  ScrollArea,
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
  SpinnerIcon,
  SquaresFourIcon,
  TypographyH5,
  TypographySmall,
} from '@/components';
import { getCategories, searchBrands } from '@/services';
import type { BrandSummary, Category } from '@/types';
import { BREAKPOINT, cn, generateFileUrl, PATHNAME, TAG } from '@/utils';

const {
  DEFAULT: {
    BASE_URL: DEFAULT_BASE_URL,
    SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR,
  },
  LOCAL: {
    MAG: { BASE_URL: MAG_BASE_URL },
    BRANDS: { BASE_URL: BRANDS_BASE_URL },
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
  },
} = PATHNAME;
const {
  SERVICE: {
    BRAND: { GLOBAL: BRAND_GLOBAL_TAG },
    CATEGORY: { GLOBAL: CATEGORY_GLOBAL_TAG },
  },
} = TAG;
const {
  LARGER_THAN: { LG: LARGER_THAN_LG },
} = BREAKPOINT;

const HeaderNavbarMenu = () => {
  const [categoryStack, setCategoryStack] = useState<Category[]>(() => []);

  const { value: isBrandsOpen, setValue: setBrandsOpen } = useBoolean();
  const { value: isMenuOpen, setValue: setMenuOpen } = useBoolean();

  const [{ data: categoriesData, isLoading }, { data: brandsData }] =
    useQueries({
      queries: [
        {
          // categories query
          queryKey: [CATEGORY_GLOBAL_TAG],
          queryFn: getCategories,
          enabled: isMenuOpen,
        },
        {
          // brands query
          queryKey: [BRAND_GLOBAL_TAG],
          queryFn: () =>
            searchBrands({
              pageNumber: 1,
              pageSize: 100,
              searchKey: '',
            }),
          enabled: isMenuOpen,
        },
      ],
    });

  const categories = categoriesData?.categories ?? [];
  const brands = brandsData?.data ?? [];
  const matches = useMediaQuery(LARGER_THAN_LG);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setCategoryStack([]);
    setBrandsOpen(false);
    setMenuOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  useEffect(() => {
    document.documentElement.setAttribute('open-menu', isMenuOpen.toString());
    setBrandsOpen(false);
    return () => {
      document.documentElement.removeAttribute('open-menu');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const initialCategory = useMemo(() => categoryStack.at(0), [categoryStack]);

  const handleDrag = (event: TouchEvent<HTMLDivElement>) => {
    const MIN_SWIPE_DISTANCE = 150; // Configurable minimum swipe distance
    const startX = event.touches[0].clientX;
    let isDragging = true;

    const handleTouchMove = (e: globalThis.TouchEvent) => {
      if (!isDragging) return;

      const currentX = e.touches[0].clientX;
      const swipeDistance = currentX - startX;

      if (swipeDistance > MIN_SWIPE_DISTANCE) {
        setMenuOpen(false);
        isDragging = false;
      }
    };

    const cleanup = () => {
      isDragging = false;
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', cleanup);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: true });
    document.addEventListener('touchend', cleanup, { once: true });
    document.addEventListener('touchcancel', cleanup, { once: true });
  };

  const { width, height } = { width: 32, height: 32 };

  const renderDesktopBrands = (brands: BrandSummary[]) => (
    <Fragment>
      <li>
        <Button
          asChild
          variant='link'
          className={cn(
            'group h-14 w-full justify-start gap-1 rounded-lg p-4 text-foreground duration-0 hover:text-primary text-wrap',
            isBrandsOpen && 'text-primary',
          )}
          onMouseEnter={() => {
            setCategoryStack([]);
            setBrandsOpen(true);
          }}
        >
          <LocaleLink href={BRANDS_BASE_URL} className='inline-flex gap-2'>
            <BrandsIcon className='size-8' />
            برند‌ها
            <CaretLeftIcon
              className={cn('ms-auto size-4 shrink-0 group-hover:fill-primary')}
            />
          </LocaleLink>
        </Button>
      </li>

      {isBrandsOpen ? (
        <div className='absolute start-full top-0 size-full border-x border-border bg-background'>
          <TypographyH5 className='flex h-16 items-center border-b border-border p-4 text-base font-semibold'>
            <LocaleLink
              href={BRANDS_BASE_URL}
              className='flex w-full items-center justify-between gap-2 hover:text-primary'
            >
              برند‌ها
              <ArrowUpLeftIcon />
            </LocaleLink>
          </TypographyH5>

          <ScrollArea className='!static h-[calc(100dvh-175px)] overflow-visible data-[orientation]:*:pt-16 [&>div]:!overflow-x-visible'>
            <ul>
              {brands.map(({ id, slug, title }) => (
                <li key={id}>
                  <Button
                    asChild
                    variant='link'
                    className='h-14 w-full justify-start rounded-lg p-4 text-foreground duration-0 hover:text-primary'
                  >
                    <LocaleLink
                      href={`${BRANDS_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
                    >
                      {title}
                    </LocaleLink>
                  </Button>
                </li>
              ))}
            </ul>
          </ScrollArea>
        </div>
      ) : null}
    </Fragment>
  );

  const renderMobileBrands = (brands: BrandSummary[]) => (
    <Fragment>
      <li className='px-3 py-1'>
        <Button
          variant='muted'
          className='h-14 w-full p-4'
          onClick={() => setBrandsOpen(true)}
        >
          <BrandsIcon />
          <TypographySmall className='ms-1.5 transition'>
            برند‌ها
          </TypographySmall>
          <ArrowUpLeftIcon className='ms-auto transition' />
        </Button>
      </li>

      <ScrollArea
        className={cn(
          '!absolute inset-0 -translate-x-full bg-background pb-2 transition-transform',
          isBrandsOpen && 'translate-x-0',
        )}
      >
        <div className='px-3 py-1'>
          <Button asChild variant='muted' className='h-14 w-full p-4'>
            <LocaleLink href={`${BRANDS_BASE_URL}`}>
              <BrandsIcon />
              <TypographySmall className='ms-1.5 transition'>
                برند‌ها
              </TypographySmall>
              <ArrowUpLeftIcon className='ms-auto transition' />
            </LocaleLink>
          </Button>
        </div>
        <ul className='px-4'>
          {brands.map(({ id, title, slug }) => (
            <li key={id} className='px-3 py-1'>
              <Button
                asChild
                variant='muted'
                className='h-14 w-full justify-start p-4'
              >
                <LocaleLink
                  href={`${BRANDS_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
                >
                  {title}
                </LocaleLink>
              </Button>
            </li>
          ))}
        </ul>
      </ScrollArea>
    </Fragment>
  );

  const renderDesktopCategories = (category: Category, depth: number) => {
    const { id, children, title, iconId, slug } = category;

    const href = `${CATEGORY_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`;
    const icon = iconId ? (
      <Image
        src={generateFileUrl('Category', iconId, width, height)}
        width={width}
        height={height}
        alt={title}
      />
    ) : null;

    const isActive = categoryStack.at(depth)?.id === id;
    const hasChildren = children.length > 0;

    const onMouseEnter = () => {
      setCategoryStack(prev => [...prev.slice(0, depth), category]);
      setBrandsOpen(false);
    };

    return hasChildren ? (
      <Fragment>
        <li key={id}>
          <Button
            asChild
            variant='link'
            className={cn(
              'group h-14 w-full justify-start gap-1 text-wrap rounded-lg p-4 text-foreground duration-0 hover:text-primary',
              isActive && 'text-primary',
            )}
            onMouseEnter={onMouseEnter}
          >
            <LocaleLink href={href}>
              {depth === 0 ? (
                <div className='inline-flex size-8'>{icon}</div>
              ) : null}
              {title}
              <CaretLeftIcon
                className={cn(
                  'ms-auto size-4 shrink-0 transition-colors group-hover:fill-primary',
                )}
              />
            </LocaleLink>
          </Button>
        </li>

        {isActive ? (
          <div className='absolute start-full top-0 size-full border-x border-border bg-background'>
            <TypographyH5 className='flex h-16 items-center border-b border-border p-4 text-base font-semibold'>
              <LocaleLink
                href={href}
                className='flex w-full items-center justify-between gap-2 hover:text-primary'
              >
                {title}
                <ArrowUpLeftIcon />
              </LocaleLink>
            </TypographyH5>

            <ScrollArea className='!static h-[calc(100dvh-175px)] overflow-visible data-[orientation]:*:pt-16 [&>div]:!overflow-x-visible'>
              <ul>
                {children.map(childCategory =>
                  renderDesktopCategories(childCategory, depth + 1),
                )}
              </ul>
            </ScrollArea>
          </div>
        ) : null}
      </Fragment>
    ) : (
      <li>
        <Button
          asChild
          key={id}
          variant='link'
          className='h-14 w-full justify-start rounded-lg p-4 text-foreground duration-0 hover:text-primary'
          onMouseEnter={onMouseEnter}
        >
          <LocaleLink href={href}>
            {icon}
            {title}
          </LocaleLink>
        </Button>
      </li>
    );
  };

  const renderMobileCategories = (category: Category, depth: number) => {
    const { id, children, title, iconId, slug } = category;

    const icon = iconId ? (
      <Image
        src={generateFileUrl('Category', iconId, width, height)}
        width={width}
        height={height}
        alt={title}
      />
    ) : null;
    const topLevelIcon = initialCategory?.iconId ? (
      <Image
        src={generateFileUrl('Category', initialCategory.iconId, width, height)}
        width={width}
        height={height}
        alt={title}
      />
    ) : null;

    const isStacked = categoryStack.includes(category);

    return children.length > 0 ? (
      <Fragment>
        <li key={id} className='px-3 py-1'>
          <Button
            variant='muted'
            className='h-14 w-full p-4'
            onClick={() => setCategoryStack(prev => [...prev, category])}
          >
            {icon}
            <TypographySmall className='ms-1.5 transition'>
              {title}
            </TypographySmall>
            {depth === 0 ? (
              <ArrowUpLeftIcon className='ms-auto transition' />
            ) : (
              <CaretLeftIcon className='ms-auto size-4 transition' />
            )}
          </Button>
        </li>

        <ScrollArea
          className={cn(
            '!absolute inset-0 -translate-x-full bg-background pb-2 transition-transform',
            isStacked && 'translate-x-0',
          )}
        >
          <div className='px-3 py-1'>
            <Button asChild variant='muted' className='h-14 w-full p-4'>
              <LocaleLink href={`${CATEGORY_BASE_URL}${initialCategory?.slug}`}>
                {topLevelIcon}
                <TypographySmall className='ms-1.5 transition'>
                  {initialCategory?.title}
                </TypographySmall>
                <ArrowUpLeftIcon className='ms-auto transition' />
              </LocaleLink>
            </Button>
          </div>
          <ul className='px-4'>
            {children.map(category =>
              renderMobileCategories(category, depth + 1),
            )}
          </ul>
        </ScrollArea>
      </Fragment>
    ) : (
      <li key={id} className='px-3 py-1'>
        <Button
          asChild
          variant='muted'
          className='h-14 w-full justify-start p-4'
        >
          <LocaleLink
            href={`${CATEGORY_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
          >
            {icon}
            {title}
          </LocaleLink>
        </Button>
      </li>
    );
  };

  const renderMobileMenuHeading = () => {
    const isStacked = categoryStack.length > 0 || isBrandsOpen;
    const showEllipsis = categoryStack.length > 2;
    const lastTwoCategories = categoryStack.slice(-2);

    return (
      <div className='flex h-16 items-center justify-center gap-2 px-6'>
        {isStacked ? (
          <Button
            variant='muted'
            size='icon'
            className='absolute start-4 top-4 size-7 p-1'
            onClick={() => {
              if (isBrandsOpen) {
                setBrandsOpen(false);
              } else {
                setCategoryStack(prev => prev.slice(0, prev.length - 1));
              }
            }}
          >
            <CaretRightIcon />
          </Button>
        ) : null}

        <Breadcrumb>
          <BreadcrumbList className='flex w-[70vw] flex-wrap justify-center text-foreground'>
            {showEllipsis ? (
              <Fragment>
                <BreadcrumbItem>...</BreadcrumbItem>
                <span className='last:hidden'>/</span>
              </Fragment>
            ) : null}
            {lastTwoCategories.length > 0 ? (
              lastTwoCategories.map(({ id, title }) => (
                <Fragment key={id}>
                  <BreadcrumbItem className='text-nowrap text-foreground/75 last-of-type:font-semibold last-of-type:text-foreground'>
                    {title}
                  </BreadcrumbItem>
                  <span className='last:hidden'>/</span>
                </Fragment>
              ))
            ) : (
              <TypographyH5 className='text-sm'>دسته‌بندی‌ها</TypographyH5>
            )}
          </BreadcrumbList>
        </Breadcrumb>
      </div>
    );
  };

  const desktopNavbarMenuContent = (
    <Fragment>
      <SheetTitle className='flex h-16 items-center gap-2 border-b border-border p-4 text-base font-semibold'>
        <SquaresFourIcon className='size-8' />
        دسته‌بندی‌ها
      </SheetTitle>

      <ScrollArea className='!static h-full overflow-visible pb-48 data-[orientation]:*:pb-28 data-[orientation]:*:pt-16 [&>div]:!overflow-x-visible'>
        <ul>
          {categories.map(category => renderDesktopCategories(category, 0))}
          {renderDesktopBrands(brands)}
        </ul>
      </ScrollArea>
    </Fragment>
  );

  const mobileNavbarMenuContent = (
    <Fragment>
      {renderMobileMenuHeading()}

      <ScrollArea className='h-[calc(100dvh-64px)] pb-2'>
        <ul>
          {categories.map(category => renderMobileCategories(category, 0))}
          {renderMobileBrands(brands)}
        </ul>
      </ScrollArea>
    </Fragment>
  );

  return (
    <Sheet
      modal={!matches}
      open={isMenuOpen}
      onOpenChange={value => {
        setTimeout(() => {
          scrollTo({ top: 0 });
        }, 0);
        setCategoryStack([]);
        setBrandsOpen(false);
        setMenuOpen(value);
      }}
    >
      <SheetTrigger className='flex items-center'>
        <div className='flex items-center gap-2 max-lg:hidden'>
          <ListIcon />
          <TypographySmall className='font-bold'>
            دسته‌بندی محصولات
          </TypographySmall>
        </div>
        <ListIcon className='size-6 lg:hidden' />
      </SheetTrigger>

      <div
        className={cn(
          'fixed inset-0 top-[111px] z-50 bg-foreground/20 transition',
          isMenuOpen
            ? 'block animate-in fade-in-20'
            : 'hidden animate-out fade-out-20',
        )}
      />

      <SheetContent
        className='overflow-visible p-0 sm:max-w-none lg:top-[111px] lg:w-[calc(100vw/5)] [&>button]:end-4 [&>button]:top-4 [&>button]:rounded [&>button]:bg-muted [&>button]:p-1 lg:[&>button]:hidden'
        onTouchStart={handleDrag}
      >
        {isLoading ? (
          <div className='flex h-full items-center justify-center'>
            <SpinnerIcon className='size-6 animate-spin text-primary' />
          </div>
        ) : matches ? (
          desktopNavbarMenuContent
        ) : (
          mobileNavbarMenuContent
        )}
      </SheetContent>
    </Sheet>
  );
};

const HeaderNavbarLinks = () => (
  <LocaleLink
    href={`${DEFAULT_BASE_URL}${MAG_BASE_URL}`}
    target='_blank'
    className='inline-flex items-center gap-2 max-lg:hidden'
  >
    <NewspaperIcon />
    <TypographySmall className='font-bold'>مجله کاشی لند</TypographySmall>
  </LocaleLink>
);

const HeaderNavbar = () => (
  <nav className='flex items-center justify-between'>
    <HeaderNavbarMenu />
    <HeaderNavbarLinks />
  </nav>
);

export { HeaderNavbar, HeaderNavbarLinks, HeaderNavbarMenu };
