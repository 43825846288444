'use client';

import { IconContext } from '@phosphor-icons/react';

const PhosphorIconsProvider = ({
  children,
}: Readonly<React.PropsWithChildren>) => (
  <IconContext.Provider
    value={{
      size: 20,
    }}
  >
    {children}
  </IconContext.Provider>
);

export { PhosphorIconsProvider };
