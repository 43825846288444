'use client';

import { useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import { useBoolean, useMediaQuery } from 'usehooks-ts';

import {
  Badge,
  BasketIcon,
  Button,
  CaretLeftIcon,
  CartCounter,
  CartCounterDecrement,
  CartCounterIncrement,
  CartCounterInput,
  CartProduct,
  CartProductBanner,
  CartProductContent,
  CartProductContentPlaceholder,
  CartProductFooterPlaceholder,
  CartProductHeader,
  CartProductHeaderPlaceholder,
  CartProductPackaging,
  CartProductPlaceholder,
  CartProductPrice,
  CartProductRemoveAction,
  CartProductTitle,
  LocaleLink,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Separator,
  ShoppingCartSimpleIcon,
  SignInIcon,
  TypographyMuted,
  TypographyParagraph,
  TypographySmall,
  UserIcon,
} from '@/components';
import { useCartAction, useCartValue, useCheckAuthValue } from '@/context';
import {
  BREAKPOINT,
  cn,
  fixedParseFloat,
  numberFormat,
  PATHNAME,
  randomId,
  shortenNumber,
  triggerPathname,
  UNIT,
} from '@/utils';

const {
  LOCAL: {
    DASHBOARD: { BASE_URL: DASHBOARD_BASE_URL },
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
    CHECKOUT: { BASE_URL: CHECKOUT_BASE_URL },
    CART: { BASE_URL: CART_BASE_URL },
    LOGIN: { BASE_URL: LOGIN_BASE_URL },
  },
  QUERY_PARAM: {
    SORT_TYPE: {
      BASE_URL: SORT_TYPE_BASE_URL,
      BEST_SELLING: SORT_TYPE_BEST_SELLING,
    },
  },
} = PATHNAME;
const {
  SMALLER_THAN: { SM: SMALLER_THAN_SM },
} = BREAKPOINT;
const { PRICE } = UNIT;

const HeaderAuthAction = () => {
  const { success } = useCheckAuthValue();

  if (success) {
    return (
      <LocaleLink href={DASHBOARD_BASE_URL}>
        <UserIcon />
        <span className='sr-only'>آیکون کاربر</span>
      </LocaleLink>
    );
  }

  return (
    <LocaleLink href={LOGIN_BASE_URL}>
      <SignInIcon />
      <span className='sr-only'>آیکون ورود</span>
    </LocaleLink>
  );
};

const HeaderCartAction = () => {
  const { value: open, setValue: setOpen } = useBoolean();
  const { products, invoice } = useCartValue();
  const cartAction = useCartAction();
  const matches = useMediaQuery(SMALLER_THAN_SM);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  const { totalPrice } = invoice;
  const productsSize = products.length;
  const isExistedProducts = productsSize > 0;

  const button = (
    <>
      <BasketIcon />
      <TypographySmall className='max-lg:sr-only'>سبد خرید</TypographySmall>
      {isExistedProducts ? (
        <Badge
          size='full'
          className='absolute -start-2 bottom-0 size-auto min-w-4 px-2 pt-px text-[10px]'
        >
          {shortenNumber(productsSize)}
        </Badge>
      ) : null}
    </>
  );

  if (matches) {
    return (
      <Button
        asChild
        variant='link'
        className='relative gap-2 p-0 text-foreground'
      >
        <LocaleLink href={CART_BASE_URL}>{button}</LocaleLink>
      </Button>
    );
  }

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <div
        className={cn('fixed inset-0 z-30 bg-foreground/30', !open && 'hidden')}
        onClick={() => setOpen(false)}
      />

      <PopoverTrigger asChild>
        <Button variant='link' className='relative gap-2 p-0 text-foreground'>
          {button}
        </Button>
      </PopoverTrigger>

      <PopoverContent
        side='bottom'
        align='end'
        className='w-[calc(100vw-4rem)] overflow-hidden p-0 lg:w-[calc(16rem+28vw)]'
      >
        <ScrollArea className={cn(isExistedProducts ? 'h-96' : 'h-full')}>
          {products ? (
            isExistedProducts ? (
              <div className='first:*:border-t-0 [&:not(:first-child)]:*:rounded-t-none'>
                {products.map(cartProduct => {
                  const {
                    subProduct: { id },
                    size,
                  } = cartProduct;

                  return (
                    <CartProduct
                      key={id}
                      className='items-center border-x-0 border-b-0 shadow-none'
                      {...cartProduct}
                    >
                      <CartProductHeader className='sm:relative'>
                        <CartProductRemoveAction
                          className='absolute start-1 top-1 rounded-none rounded-ee rounded-ss bg-background'
                          onClick={() => {
                            cartAction({
                              type: 'DELETE_CART_PRODUCT',
                              payload: cartProduct,
                            });
                          }}
                        />
                        <CartProductBanner className='h-28' />
                      </CartProductHeader>
                      <CartProductContent className='flex-1 grid-cols-12'>
                        <CartProductTitle className='col-span-7' />
                        <CartCounter
                          className='col-span-5 ms-auto max-w-52'
                          {...cartProduct}
                        >
                          <CartCounterIncrement
                            onClick={() => {
                              cartAction({
                                type: 'UPDATE_CART_PRODUCT',
                                payload: {
                                  ...cartProduct,
                                  size: size + 1,
                                },
                              });
                            }}
                          />
                          <CartCounterInput
                            onValueChange={value => {
                              cartAction({
                                type: 'UPDATE_CART_PRODUCT',
                                payload: {
                                  ...cartProduct,
                                  size: value,
                                },
                              });
                            }}
                          />
                          <CartCounterDecrement
                            onClick={() => {
                              cartAction({
                                type: 'UPDATE_CART_PRODUCT',
                                payload: {
                                  ...cartProduct,
                                  size: size - 1,
                                },
                              });
                            }}
                          />
                        </CartCounter>
                        <CartProductPackaging className='col-span-6' />
                        <CartProductPrice className='col-span-6 justify-self-end' />
                      </CartProductContent>
                    </CartProduct>
                  );
                })}
              </div>
            ) : (
              <div className='my-20 flex size-full flex-col items-center justify-center'>
                <ShoppingCartSimpleIcon className='mb-5 size-20 text-muted-foreground' />

                <TypographyMuted>
                  سبد خرید شما در حال حاضر خالی است.
                </TypographyMuted>

                <Button asChild variant='link'>
                  <LocaleLink
                    href={{
                      pathname: CATEGORY_BASE_URL,
                      query: { [SORT_TYPE_BASE_URL]: SORT_TYPE_BEST_SELLING },
                    }}
                  >
                    <TypographySmall>برو به پرفروش ترین‌ها</TypographySmall>
                    <CaretLeftIcon className='size-3.5' />
                  </LocaleLink>
                </Button>
              </div>
            )
          ) : (
            <div className='[&:not(:first-child)]:*:rounded-t-none'>
              {Array.from<number, string>({ length: 4 }, (_v, _k) =>
                randomId(),
              ).map(value => (
                <CartProductPlaceholder
                  key={value}
                  className='border-x-0 border-y-transparent shadow-none max-lg:grid-rows-3'
                >
                  <CartProductHeaderPlaceholder />
                  <CartProductContentPlaceholder />
                  <CartProductFooterPlaceholder />
                </CartProductPlaceholder>
              ))}
            </div>
          )}
        </ScrollArea>

        {isExistedProducts && totalPrice ? (
          <div>
            <Separator />

            <div className='container flex items-center justify-between gap-4 py-4'>
              <div className='flex flex-col gap-2'>
                <TypographyMuted className='text-base'>
                  مبلغ قابل پرداخت:
                </TypographyMuted>
                <TypographyParagraph>
                  <span className='text-xl font-bold'>
                    {numberFormat(totalPrice)}
                  </span>
                  <span className='ms-1 text-sm'>{PRICE}</span>
                </TypographyParagraph>
              </div>

              <Button
                asChild
                className='h-auto rounded-md px-4 py-3 font-semibold'
              >
                <LocaleLink href={CART_BASE_URL}>رفتن به سبد خرید</LocaleLink>
              </Button>
            </div>
          </div>
        ) : null}
      </PopoverContent>
    </Popover>
  );
};

const HeaderAction = () => {
  const pathname = usePathname();

  const triggeredExcludedPathname = triggerPathname(pathname, [
    CHECKOUT_BASE_URL,
  ]);

  return (
    <div className='flex items-center justify-end gap-6'>
      <HeaderAuthAction />
      {!triggeredExcludedPathname ? (
        <>
          <Separator
            orientation='vertical'
            className='h-5 w-0.5 shrink-0 max-lg:hidden'
          />
          <HeaderCartAction />
        </>
      ) : null}
    </div>
  );
};

export { HeaderAction, HeaderAuthAction, HeaderCartAction };
