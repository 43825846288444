import { createElement } from 'react';

import {
  AparatIcon,
  Button,
  ExternalLink,
  InstagramLogoIcon,
  LinkedinLogoIcon,
  PinterestLogoIcon,
  TelegramLogoIcon,
  WhatsappLogoIcon,
  XLogoIcon,
  YoutubeLogoIcon,
} from '@/components';
import { PATHNAME, randomId } from '@/utils';

const {
  SOCIAL_MEDIA: {
    YOUTUBE: { BASE_URL: YOUTUBE_BASE_URL, SLUG: YOUTUBE_SLUG },
    APARAT: { BASE_URL: APARAT_BASE_URL, SLUG: APARAT_SLUG },
    INSTAGRAM: { BASE_URL: INSTAGRAM_BASE_URL, SLUG: INSTAGRAM_SLUG },
    PINTEREST: { BASE_URL: PINTEREST_BASE_URL, SLUG: PINTEREST_SLUG },
    LINKEDIN: { BASE_URL: LINKEDIN_BASE_URL, SLUG: LINKEDIN_SLUG },
    TWITTER: { BASE_URL: TWITTER_BASE_URL, SLUG: TWITTER_SLUG },
    TELEGRAM: { BASE_URL: TELEGRAM_BASE_URL, SLUG: TELEGRAM_SLUG },
    WHATSAPP: { BASE_URL: WHATSAPP_BASE_URL, SLUG: WHATSAPP_SLUG },
  },
} = PATHNAME;

const FooterMedias = () => (
  <div className='flex gap-4 max-xl:flex-wrap max-lg:justify-center'>
    {[
      {
        title: 'شبکه اجتماعی آپارات',
        href: `${APARAT_BASE_URL}${APARAT_SLUG}`,
        icon: AparatIcon,
      },
      {
        title: 'شبکه اجتماعی تلگرام',
        href: `${TELEGRAM_BASE_URL}${TELEGRAM_SLUG}`,
        icon: TelegramLogoIcon,
      },
      {
        title: 'شبکه اجتماعی اینستاگرام',
        href: `${INSTAGRAM_BASE_URL}${INSTAGRAM_SLUG}`,
        icon: InstagramLogoIcon,
      },
      {
        title: 'شبکه اجتماعی توییتر(ایکس)',
        href: `${TWITTER_BASE_URL}${TWITTER_SLUG}`,
        icon: XLogoIcon,
      },
      {
        title: 'شبکه اجتماعی یوتیوب',
        href: `${YOUTUBE_BASE_URL}${YOUTUBE_SLUG}`,
        icon: YoutubeLogoIcon,
      },
      {
        title: 'شبکه اجتماعی لینکدین',
        href: `${LINKEDIN_BASE_URL}${LINKEDIN_SLUG}`,
        icon: LinkedinLogoIcon,
      },
      {
        title: 'شبکه اجتماعی واتساپ',
        href: `${WHATSAPP_BASE_URL}${WHATSAPP_SLUG}`,
        icon: WhatsappLogoIcon,
      },
      {
        title: 'شبکه اجتماعی پینترست',
        href: `${PINTEREST_BASE_URL}${PINTEREST_SLUG}`,
        icon: PinterestLogoIcon,
      },
    ].map(({ title, href, icon }) => {
      const id = randomId();
      return (
        <Button
          asChild
          key={id}
          variant='background'
          size='icon'
          className='size-11 rounded-full text-primary'
        >
          <ExternalLink href={href}>
            {createElement(icon)}
            <span className='sr-only'>{title}</span>
          </ExternalLink>
        </Button>
      );
    })}
  </div>
);

export { FooterMedias };
