import { useQuery } from '@tanstack/react-query';

import {
  LocaleLink,
  Skeleton,
  TypographyBalancer,
  TypographySmall,
} from '@/components';
import { getFooterInfo } from '@/services';
import { PATHNAME, randomId, TAG } from '@/utils';

const {
  DEFAULT: {
    BASE_URL: DEFAULT_BASE_URL,
    SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR,
  },
  LOCAL: {
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
    ABOUT: { BASE_URL: ABOUT_BASE_URL },
    CONTACT: { BASE_URL: CONTACT_BASE_URL },
    MAG: { BASE_URL: MAG_BASE_URL },
    SITEMAP: {
      BASE_URL: SITEMAP_BASE_URL,
      SUFFIX: SITEMAP_SUFFIX,
      EXTENSION: SITEMAP_EXTENSION,
    },
  },
} = PATHNAME;
const {
  SERVICE: {
    PAGE_BUILDER: {
      PAGE_FOOTER_INFO: { BASE: BASE_PAGE_FOOTER_INFO },
    },
  },
} = TAG;

const FooterLinks = () => {
  const { isSuccess, data } = useQuery({
    queryKey: [BASE_PAGE_FOOTER_INFO],
    queryFn: getFooterInfo,
    staleTime: Infinity,
  });

  const { categories = [], bestSellerCategories = [], posts = [] } = data ?? {};

  return (
    <div className='flex gap-8 max-lg:flex-wrap max-sm:flex-col lg:justify-between'>
      {[
        {
          title: 'آشنایی با ما',
          children: [
            {
              title: 'درباره کاشی لند',
              href: ABOUT_BASE_URL,
              target: '_self',
            },
            {
              title: 'تماس با ما',
              href: CONTACT_BASE_URL,
              target: '_self',
            },
            {
              title: 'مجله کاشی لند',
              href: `${DEFAULT_BASE_URL}${MAG_BASE_URL}`,
              target: '_self',
            },
            {
              title: 'نقشه سایت',
              href: `${DEFAULT_SLUG_SEPARATOR}${SITEMAP_BASE_URL}${SITEMAP_SUFFIX}${SITEMAP_EXTENSION}`,
              target: '_blank',
            },
          ],
        },
        {
          title: 'دسترسی سریع',
          children: categories.map(({ title, slug }) => ({
            title,
            href: `${CATEGORY_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
            target: '_self',
          })),
        },
        {
          title: 'محصولات پرفروش',
          children: bestSellerCategories.map(({ title, slug }) => ({
            title,
            href: `${CATEGORY_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
            target: '_self',
          })),
        },
        {
          title: 'آخرین مقالات سایت',
          children: posts.map(({ title, link: href }) => ({
            title,
            href,
            target: '_blank',
          })),
        },
      ].map(({ title, children }) => {
        const id = randomId();
        return (
          <div key={id} className='space-y-4 [&_p]:first:!text-nowrap'>
            <TypographySmall className='text-nowrap text-base font-semibold'>
              {title}
            </TypographySmall>
            {isSuccess ? (
              <ul className='space-y-4'>
                {children.map(({ title, href, target }) => {
                  const id = randomId();
                  return (
                    <li key={id} className='group flex items-center'>
                      <span className='inline-block h-px w-2 translate-x-2 scale-0 bg-primary transition-all group-hover:translate-x-0 group-hover:scale-100' />
                      <TypographyBalancer className='translate-x-2 text-sm text-secondary transition-all group-hover:-translate-x-2 group-hover:text-primary'>
                        <LocaleLink href={href} target={target}>
                          {title}
                        </LocaleLink>
                      </TypographyBalancer>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className='space-y-4'>
                {Array.from<number, string>({ length: 4 }, (v, k) =>
                  randomId(),
                ).map(value => (
                  <Skeleton key={value} className='h-2 w-full bg-secondary' />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export { FooterLinks };
