'use client';

import { useEffect, useRef, useState } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {
  useBoolean,
  useDebounceValue,
  useEventListener,
  useLocalStorage,
  useMediaQuery,
} from 'usehooks-ts';
import { z } from 'zod';

import {
  ArrowLeftIcon,
  BrandCard,
  BrandCardContent,
  BrandCardHeader,
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  LocaleLink,
  MagnifyingGlassIcon,
  ProductCard,
  ProductCardContent,
  ProductCardFooter,
  ProductCardHeader,
  ScrollArea,
  Separator,
  SpinnerIcon,
  SquaresFourIcon,
  TypographyMuted,
} from '@/components';
import { briefSearchProducts } from '@/services';
import type { ProductsBriefResult } from '@/types';
import { BREAKPOINT, cn, PATHNAME, randomId, STORAGE, TAG } from '@/utils';

const {
  LOCAL: {
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
  },
  QUERY_PARAM: {
    SYMBOL: {
      QUESTION_MARK: QUESTION_MARK_SYMBOL,
      ASSIGNMENT: ASSIGNMENT_SYMBOL,
    },
    SEARCH_KEY: { BASE_URL: SEARCH_KEY_BASE_URL },
    CATEGORIES_IDS: { BASE_URL: CATEGORIES_IDS_BASE_URL },
    SORT_TYPE: {
      BASE_URL: SORT_TYPE_BASE_URL,
      BEST_SELLING: SORT_TYPE_BEST_SELLING,
    },
  },
} = PATHNAME;
const {
  SERVICE: {
    PRODUCT: {
      BRIEF_SEARCH_GLOBAL: { BASE: BASE_BRIEF_SEARCH_GLOBAL_PRODUCT },
    },
  },
} = TAG;
const {
  LOCAL: {
    SEARCH: { HISTORY: LOCAL_SEARCH_HISTORY },
  },
} = STORAGE;
const {
  LARGER_THAN: { LG: LARGER_THAN_LG },
} = BREAKPOINT;

type HeaderSearchBrandsProps = Pick<ProductsBriefResult, 'relatedBrands'>;

const HeaderSearchBrands = ({ relatedBrands }: HeaderSearchBrandsProps) => (
  <div className='space-y-4'>
    <TypographyMuted>برندهای مرتبط</TypographyMuted>

    <Carousel>
      <CarouselContent className='-ms-3'>
        {relatedBrands.map(brand => (
          <CarouselItem key={brand.id} className='basis-44'>
            <BrandCard
              className='grid-cols-2 items-center gap-0 p-2'
              {...brand}
            >
              <BrandCardHeader className='h-8' />
              <BrandCardContent />
            </BrandCard>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className='-start-2 size-10 [&>svg]:size-[1.125rem]' />
      <CarouselNext className='-end-2 size-10 [&>svg]:size-[1.125rem]' />
    </Carousel>
  </div>
);

type HeaderSearchProductsProps = Pick<ProductsBriefResult, 'products'>;

const HeaderSearchProducts = ({ products }: HeaderSearchProductsProps) => (
  <div className='space-y-4'>
    <TypographyMuted>محصولات مرتبط</TypographyMuted>

    <Carousel>
      <CarouselContent>
        {products.map(product => (
          <CarouselItem key={product.id} className='basis-full lg:basis-11/12'>
            <ProductCard
              className='grid-cols-3 grid-rows-2 gap-2 p-3'
              {...product}
            >
              <ProductCardHeader className='row-span-2 h-20' />
              <ProductCardContent className='col-span-2' />
              <ProductCardFooter className='col-span-2 col-start-2 row-start-2 [&_.discount-badge]:static' />
            </ProductCard>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className='-start-2 size-10 [&>svg]:size-[1.125rem]' />
      <CarouselNext className='-end-2 size-10 [&>svg]:size-[1.125rem]' />
    </Carousel>
  </div>
);

type HeaderSearchCategoriesProps = Pick<
  ProductsBriefResult,
  'relatedCategories'
>;

const HeaderSearchCategories = ({
  relatedCategories,
}: HeaderSearchCategoriesProps) => (
  <div className='space-y-4'>
    <TypographyMuted>نتایج دسته‌ها</TypographyMuted>

    <ScrollArea className={cn(relatedCategories.length > 0 && 'h-44')}>
      <div className='space-y-2'>
        {relatedCategories.map(({ id, title }) => (
          <div key={id} className='flex items-center gap-2'>
            <SquaresFourIcon className='text-muted-foreground' />

            <div className='inline-flex items-center gap-0.5 text-sm'>
              <span>در</span>
              <LocaleLink
                href={{
                  pathname: CATEGORY_BASE_URL,
                  query: { [CATEGORIES_IDS_BASE_URL]: id },
                }}
                title={title}
                className='text-primary lg:w-[9.75rem] lg:truncate'
              >
                {title}
              </LocaleLink>
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  </div>
);

type HeaderSearchKeysProps = Pick<ProductsBriefResult, 'relatedSearchKeys'>;

const HeaderSearchKeys = ({ relatedSearchKeys }: HeaderSearchKeysProps) => (
  <div className='space-y-4'>
    <TypographyMuted>نتایج جستجو</TypographyMuted>

    <ScrollArea className={cn(relatedSearchKeys.length > 0 && 'h-32')}>
      <div className='space-y-2'>
        {relatedSearchKeys.map(searchKey => {
          const id = randomId();

          return (
            <div key={id} className='flex items-center gap-2'>
              <MagnifyingGlassIcon className='text-muted-foreground' />

              <LocaleLink
                href={{
                  pathname: CATEGORY_BASE_URL,
                  query: { [SEARCH_KEY_BASE_URL]: searchKey },
                }}
                title={searchKey}
                target='_parent'
                className='text-sm lg:w-[9.75rem] lg:truncate'
              >
                {searchKey}
              </LocaleLink>
            </div>
          );
        })}
      </div>
    </ScrollArea>
  </div>
);

type HeaderSearchHistoryProps = {
  searchHistory: string[];
};

const HeaderSearchHistory = ({ searchHistory }: HeaderSearchHistoryProps) => (
  <div className='min-h-20 space-y-4'>
    <TypographyMuted>تاریخچه جستجو شما</TypographyMuted>

    <div className='flex flex-wrap items-center gap-2'>
      {searchHistory.map(searchKey => {
        const id = randomId();

        return (
          <Button
            asChild
            key={id}
            variant='outline'
            size='sm'
            className='rounded-md'
          >
            <LocaleLink
              href={{
                pathname: CATEGORY_BASE_URL,
                query: { [SEARCH_KEY_BASE_URL]: searchKey },
              }}
              target='_parent'
            >
              {searchKey}
            </LocaleLink>
          </Button>
        );
      })}
    </div>
  </div>
);

const HeaderSearchEmpty = () => (
  <div className='flex flex-col items-center justify-center gap-4 py-8'>
    <TypographyMuted className='text-base'>نتیجه‌ای یافت نشد</TypographyMuted>
    <Button asChild variant='link' className='h-auto gap-2 p-0'>
      <LocaleLink
        href={{
          pathname: CATEGORY_BASE_URL,
          query: { [SORT_TYPE_BASE_URL]: SORT_TYPE_BEST_SELLING },
        }}
      >
        مشاهده پرفروش‌ترین محصولات <ArrowLeftIcon />
      </LocaleLink>
    </Button>
  </div>
);

const FormSchema = z.object({
  searchKey: z.string(),
});

const HeaderSearch = () => {
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(
    LOCAL_SEARCH_HISTORY,
    [],
  );
  const [searchKey, setSearchKey] = useState('');
  const [debouncedSearchKey] = useDebounceValue(searchKey, 500);
  const { value: isSearchOpen, setValue: setSearchOpen } = useBoolean();
  const { isFetching, data } = useQuery({
    queryKey: [BASE_BRIEF_SEARCH_GLOBAL_PRODUCT, debouncedSearchKey],
    queryFn: () => briefSearchProducts({ searchKey: debouncedSearchKey }),
    enabled: !!debouncedSearchKey,
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      searchKey: '',
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const matches = useMediaQuery(LARGER_THAN_LG);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setSearchKey('');
    setSearchOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  useEventListener('keydown', event => {
    if (event.key === 'Escape') {
      setSearchOpen(false);
      inputRef.current?.blur();
    }
  });

  const {
    products = [],
    relatedCategories = [],
    relatedBrands = [],
    relatedSearchKeys = [],
  } = data ?? {};
  const isSearchedKey = debouncedSearchKey.trim();

  const onSearchHistoryChange = () => {
    if (isSearchedKey) {
      setSearchHistory(prevSearchHistory => {
        const updatedHistory = new Set([
          debouncedSearchKey,
          ...prevSearchHistory,
        ]);

        // Convert the set back to an array and slice the last 10 entries
        return Array.from(updatedHistory).slice(0, 10);
      });
    }
  };

  const onSubmit = () => {
    onSearchHistoryChange();

    if (isSearchedKey) {
      location.assign(
        `${CATEGORY_BASE_URL}${QUESTION_MARK_SYMBOL}${SEARCH_KEY_BASE_URL}${ASSIGNMENT_SYMBOL}${debouncedSearchKey}`,
      );
    }
  };

  const isEmptyProducts = products.length === 0;
  const isEmptyCategories = relatedCategories.length === 0;
  const isEmptyBrands = relatedBrands.length === 0;
  const isEmptySearchKeys = relatedSearchKeys.length === 0;
  const isEmptyHistory = searchHistory.length === 0;

  const isEmptyResults = isEmptyBrands && isEmptyCategories && isEmptyProducts;
  const hideSeparator =
    (isEmptyProducts && isEmptyBrands && isEmptyHistory) ||
    (isEmptyCategories && isEmptySearchKeys);

  return (
    <div className='relative'>
      <div
        className={cn(
          'fixed inset-0 z-20 bg-foreground/30 transition',
          !isSearchOpen && 'hidden',
        )}
        onClick={() => setSearchOpen(false)}
      />

      <Form {...form}>
        <form
          className='relative z-20 lg:mx-auto lg:max-w-screen-sm'
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormField
            control={form.control}
            name='searchKey'
            render={() => (
              <FormItem className='space-y-0'>
                <MagnifyingGlassIcon
                  className='absolute start-4 top-1/2 -translate-y-1/2 text-muted-foreground'
                  strokeWidth={1.5}
                />

                <FormControl>
                  <Input
                    ref={inputRef}
                    autoComplete='off'
                    className='truncate ps-10 focus-visible:ring-0'
                    placeholder='جست‌و‌جو از میان کالا ، دسته بندی و برندها...'
                    value={searchKey}
                    onChange={({ target: { value } }) => {
                      setSearchKey(value);
                    }}
                    onFocus={() => setSearchOpen(true)}
                    onBlur={() => {
                      if (!searchKey && searchHistory.length === 0) {
                        setSearchOpen(false);
                      }
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>

      <div
        className={cn(
          'absolute inset-x-0 z-20 mt-2 overflow-hidden rounded-md bg-card shadow-md',
          !isSearchOpen && 'invisible',
        )}
      >
        {isFetching ? (
          <div className='flex h-40 items-center justify-center'>
            <SpinnerIcon className='size-6 animate-spin text-primary' />
          </div>
        ) : isSearchedKey ? (
          !isEmptyResults ? (
            <div className='flex max-lg:flex-col'>
              <div className='space-y-8 p-4 lg:min-w-[35%]'>
                {!isEmptySearchKeys ? (
                  <HeaderSearchKeys relatedSearchKeys={relatedSearchKeys} />
                ) : null}
                {!isEmptyCategories ? (
                  <HeaderSearchCategories
                    relatedCategories={relatedCategories}
                  />
                ) : null}
              </div>

              {!hideSeparator ? (
                <Separator
                  orientation={matches ? 'vertical' : 'horizontal'}
                  className='shrink-0'
                />
              ) : null}

              <div className='space-y-8 overflow-hidden p-4 lg:min-w-[60%]'>
                <HeaderSearchHistory searchHistory={searchHistory} />
                {!isEmptyProducts ? (
                  <HeaderSearchProducts products={products} />
                ) : null}
                {!isEmptyBrands ? (
                  <HeaderSearchBrands relatedBrands={relatedBrands} />
                ) : null}
              </div>
            </div>
          ) : (
            <HeaderSearchEmpty />
          )
        ) : !isEmptyHistory ? (
          <div className='p-4'>
            <HeaderSearchHistory searchHistory={searchHistory} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export {
  HeaderSearch,
  HeaderSearchBrands,
  HeaderSearchCategories,
  HeaderSearchHistory,
  HeaderSearchKeys,
  HeaderSearchProducts,
};
