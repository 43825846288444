import {
  PelleIcon,
  TypographyMuted,
  TypographyParagraph,
  TypographyStrong,
} from '@/components';

const FooterRights = () => (
  <div className='space-y-2 max-lg:text-center max-lg:[&_span]:text-xs max-lg:[&_span]:text-secondary'>
    <TypographyParagraph>
      <TypographyMuted>
        کلیه حقوق مادی و معنوی این وبسایت متعلق به کاشی‌لند است.
      </TypographyMuted>
    </TypographyParagraph>
    <TypographyParagraph className='*:inline-flex'>
      <TypographyMuted>طراحی و توسعه توسط</TypographyMuted>
      <TypographyStrong className='mx-1 text-base'>پله</TypographyStrong>
      <PelleIcon className='mb-1 size-5' />
    </TypographyParagraph>
  </div>
);

export { FooterRights };
