import {
  Button,
  MailboxIcon,
  MailLink,
  MapPinIcon,
  PhoneIcon,
  TelLink,
  TypographyAddress,
} from '@/components';
import { PATHNAME } from '@/utils';

const {
  CONTACT: {
    PUBLIC: {
      EMAIL: PUBLIC_EMAIL,
      PHONE: PUBLIC_PHONE,
      FORMATTED_PHONE: PUBLIC_FORMATTED_PHONE,
      ADDRESS: PUBLIC_ADDRESS,
    },
  },
} = PATHNAME;

const FooterContacts = () => (
  <div className='flex flex-wrap items-center gap-4'>
    <div className='flex items-center gap-2'>
      <div>
        <MapPinIcon className='text-primary' />
        <span className='sr-only'>آیکون نقشه</span>
      </div>

      <TypographyAddress className='text-secondary'>
        {PUBLIC_ADDRESS}
      </TypographyAddress>
    </div>

    <div className='flex items-center gap-2'>
      <div>
        <PhoneIcon className='text-primary' />
        <span className='sr-only'>آیکون تماس</span>
      </div>

      <Button
        asChild
        variant='link'
        size='icon'
        className='size-auto text-secondary'
      >
        <TelLink href={PUBLIC_PHONE}>{PUBLIC_FORMATTED_PHONE}</TelLink>
      </Button>
    </div>

    <div className='flex items-center gap-2'>
      <div>
        <MailboxIcon className='text-primary' />
        <span className='sr-only'>آیکون ایمیل</span>
      </div>

      <Button
        asChild
        variant='link'
        size='icon'
        className='size-auto text-secondary'
      >
        <MailLink href={PUBLIC_EMAIL}>{PUBLIC_EMAIL}</MailLink>
      </Button>
    </div>
  </div>
);

export { FooterContacts };
