import(/* webpackMode: "eager" */ "/kashiland-front-client/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/iran-yekan/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./thin.woff\",\"weight\":\"100\"},{\"path\":\"./light.woff\",\"weight\":\"300\"},{\"path\":\"./regular.woff\",\"weight\":\"400\"},{\"path\":\"./medium.woff\",\"weight\":\"500\"},{\"path\":\"./bold.woff\",\"weight\":\"700\"},{\"path\":\"./extra-bold.woff\",\"weight\":\"800\"},{\"path\":\"./black.woff\",\"weight\":\"900\"},{\"path\":\"./extra-black.woff\",\"weight\":\"950\"}],\"style\":\"normal\",\"variable\":\"--font-iran-yekan\",\"display\":\"swap\"}],\"variableName\":\"iran_yekan\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/kashiland-front-client/node_modules/react-wrap-balancer/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/kashiland-front-client/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/assets/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/context/cart/index.tsx");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/context/check-auth/index.tsx");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/context/checkout/index.tsx");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/context/login/index.tsx");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/context/product/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PublicFooter"] */ "/kashiland-front-client/src/layouts/public/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PublicHeader"] */ "/kashiland-front-client/src/layouts/public/header/index.tsx");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/providers/components/phosphor-icons.tsx");
;
import(/* webpackMode: "eager" */ "/kashiland-front-client/src/providers/components/react-query.tsx");
